import { useMutation } from '@apollo/client'
import { Box, Button, Fade, Grid, TextField, Typography, useTheme } from '@material-ui/core'
import Dropdown from 'components/Dropdown'
import ErrorBox from 'components/ErrorBox'
import FieldWrapper from 'components/FieldWrapper'
import ViewWrapper from 'components/ViewWrapper'
import { PATCH_CUSTOMPROPS } from 'graphql/mutations/PatchCustomProps'
import { Mutation, MutationPatchCustomerPropsArgs } from 'graphql/types'
import useEditState from 'hooks/useEditState'
import { editField } from 'hooks/useEditState/EditStateFuncs'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InputMask from 'react-input-mask'
import { checkPhoneErrors, isPhoneRegex, isValidPhone } from 'utils/isValidPhoneNumber'
import TestIDs from 'utils/TestIDs'
import { dropdownOptionsSalutation, getHeader, navigationLinks, Salutation } from 'utils/utils'

const PersonalDataEdit = (): JSX.Element => {
    const { user, userCopy, history, searchTerms, saveUserData, setUserCopy, validation, setValidation, touched } =
        useEditState(editField.personalData)
    const { enqueueSnackbar } = useSnackbar()

    if (!userCopy || Object.keys(userCopy ?? {}).length === 0) {
        history.push('/')
        return <></>
    }

    const [saveClicked, setSaveClicked] = useState(false)

    const [patchProps, { loading }] = useMutation<Mutation>(PATCH_CUSTOMPROPS, {
        onCompleted: (data) => {
            if (data.patchCustomerProps === true) {
                saveSuccess()
            } else {
                enqueueSnackbar(t('notification.saveFailed'), {
                    variant: 'error',
                })
            }
        },
    })

    const checkNumbers = () => {
        const newErrors: string[] = []
        if (userCopy.telephone !== '' && userCopy.telephone !== user.telephone) {
            //check telephone number isValid
            const tel = isValidPhone(userCopy.telephone)
            if (tel.isValid && tel.isPossible && tel.isValidLength === undefined) {
                //vars.input.push({ specifier: 'phone', value: userCopy.telephone })
            } else {
                const errs = checkPhoneErrors(tel, 'TEL_')
                if (errs.length > 0) {
                    newErrors.push(...errs)
                }
            }
        }

        if (userCopy.mobile !== '' && userCopy.mobile !== user.mobile) {
            const mobile = isValidPhone(userCopy.mobile)
            if (mobile.isValid && mobile.isPossible && mobile.isValidLength === undefined) {
                //vars.input.push({ specifier: 'mobile', value: userCopy.mobile })
            } else {
                const errs = checkPhoneErrors(mobile, 'MOBILE_')
                if (errs.length > 0) {
                    newErrors.push(...errs)
                }
            }
        }
        setValidation({ errors: newErrors })
    }

    const handleSave = () => {
        const vars: MutationPatchCustomerPropsArgs = {
            input: [],
        }
        if (userCopy.telephone === '' && userCopy.telephone !== user.telephone) {
            vars.input.push({ specifier: 'phone', value: 'DELETE' })
        }
        if (userCopy.mobile === '' && userCopy.mobile !== user.mobile) {
            vars.input.push({ specifier: 'mobile', value: 'DELETE' })
        }
        setSaveClicked(true)
        const newErrors: string[] = []

        if (userCopy.telephone !== '' && userCopy.telephone !== user.telephone) {
            //check telephone number isValid
            const tel = isValidPhone(userCopy.telephone)
            if (tel.isValid && tel.isPossible && tel.isValidLength === undefined) {
                vars.input.push({ specifier: 'phone', value: userCopy.telephone })
            } else {
                const errs = checkPhoneErrors(tel, 'TEL_')
                if (errs.length > 0) {
                    newErrors.push(...errs)
                }
            }
        }

        if (userCopy.mobile !== '' && userCopy.mobile !== user.mobile) {
            const mobile = isValidPhone(userCopy.mobile)
            if (mobile.isValid && mobile.isPossible && mobile.isValidLength === undefined) {
                vars.input.push({ specifier: 'mobile', value: userCopy.mobile })
            } else {
                const errs = checkPhoneErrors(mobile, 'MOBILE_')
                if (errs.length > 0) {
                    newErrors.push(...errs)
                }
            }
        }
        if (newErrors.length > 0) {
            setValidation({ errors: newErrors })
        } else if (vars.input.length > 0) {
            patchProps({ variables: vars })
        } else {
            // no changes just go back
            //NOTE: as i remember katha required not to go back on save
            //history.goBack()
        }
    }
    const saveSuccess = (): void => {
        saveUserData(userCopy)
    }
    const handleCancel = (): void => {
        history.goBack()
    }

    const { t } = useTranslation()
    const theme = useTheme()

    useEffect(() => {
        if (saveClicked) {
            checkNumbers()
        }
    }, [userCopy])

    return (
        <ViewWrapper
            header={getHeader(`/${t('url.dashboard')}`, history, t, searchTerms)}
            navigation={navigationLinks('logged-in', 'myData', t)}
        >
            <>
                <Box marginBottom={5}>
                    <Typography variant={'h2'}>{t('pages.myData.edit.PersonalData')}</Typography>
                </Box>
                <FieldWrapper text={''}>
                    <Grid container item spacing={2} style={{ backgroundColor: theme.palette.primary.light }}>
                        <Grid item xs={12} md={6}>
                            <Dropdown
                                id={'salutation'}
                                disabled
                                error={false}
                                label={t('formLabels.salutation')}
                                options={dropdownOptionsSalutation(t)}
                                selectedOption={
                                    userCopy.salutation && userCopy.salutation.trim().length > 0
                                        ? userCopy.salutation
                                        : Salutation.NONE
                                }
                                handleChange={(e): void => {
                                    setUserCopy({ ...userCopy, salutation: e.target.value as Salutation })
                                }}
                                testIdentifier={TestIDs.GET_EDITABLE_PERSONAL_DATA('SALUTATION')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                disabled
                                fullWidth={true}
                                label={t('formLabels.title')}
                                variant={'filled'}
                                value={userCopy.title}
                                // onChange={(e): void => setUserCopy({ ...userCopy, title: e.target.value })}
                                {...TestIDs.GET_EDITABLE_PERSONAL_DATA('TITLE')}
                            />
                        </Grid>
                        {userCopy.company && userCopy.company.trim().length > 0 && (
                            <>
                                <Grid item xs={12}>
                                    <TextField
                                        disabled
                                        fullWidth={true}
                                        label={t('formLabels.company')}
                                        variant={'filled'}
                                        value={userCopy.company}
                                        // onChange={(e): void => setUserCopy({ ...userCopy, firstName: e.target.value })}
                                        {...TestIDs.GET_EDITABLE_PERSONAL_DATA('COMPANY')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        disabled
                                        fullWidth={true}
                                        label={t('formLabels.legalForm')}
                                        variant={'filled'}
                                        value={userCopy.companyLegalForm}
                                        // onChange={(e): void => setUserCopy({ ...userCopy, firstName: e.target.value })}
                                        {...TestIDs.GET_EDITABLE_PERSONAL_DATA('LEGAL_FORM')}
                                    />
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12}>
                            <TextField
                                disabled
                                fullWidth={true}
                                label={t('formLabels.firstName')}
                                variant={'filled'}
                                value={userCopy.firstName}
                                // onChange={(e): void => setUserCopy({ ...userCopy, firstName: e.target.value })}
                                {...TestIDs.GET_EDITABLE_PERSONAL_DATA('FIRST_NAME')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                disabled
                                fullWidth={true}
                                label={t('formLabels.lastName')}
                                variant={'filled'}
                                value={userCopy.lastName}
                                // onChange={(e): void => setUserCopy({ ...userCopy, lastName: e.target.value })}
                                {...TestIDs.GET_EDITABLE_PERSONAL_DATA('LAST_NAME')}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                fullWidth={true}
                                label={t('formLabels.street')}
                                disabled
                                variant={'filled'}
                                value={userCopy.connectionAddress.street}
                                {...TestIDs.GET_EDITABLE_PERSONAL_DATA('STREET')}
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <TextField
                                fullWidth={true}
                                label={t('formLabels.houseNumber')}
                                variant={'filled'}
                                disabled
                                value={userCopy.connectionAddress.houseNumber}
                                {...TestIDs.GET_EDITABLE_PERSONAL_DATA('NUMBER')}
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <TextField
                                fullWidth={true}
                                label={t('formLabels.houseNumberExt')}
                                variant={'filled'}
                                disabled
                                value={userCopy.connectionAddress.houseNumberExt}
                                {...TestIDs.GET_EDITABLE_PERSONAL_DATA('NUMBER_EXT')}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <InputMask
                                mask={'99999'}
                                alwaysShowMask={false}
                                placeholder={''}
                                disabled
                                value={userCopy.connectionAddress.zipCode}
                            >
                                {(): JSX.Element => (
                                    <TextField
                                        fullWidth={true}
                                        label={t('formLabels.zip')}
                                        variant={'filled'}
                                        disabled
                                        {...TestIDs.GET_EDITABLE_PERSONAL_DATA('ZIP')}
                                    />
                                )}
                            </InputMask>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <TextField
                                fullWidth={true}
                                label={t('formLabels.city')}
                                variant={'filled'}
                                disabled
                                value={userCopy.connectionAddress.city}
                                {...TestIDs.GET_EDITABLE_PERSONAL_DATA('CITY')}
                            />
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <TextField
                                disabled
                                fullWidth={true}
                                label={t('formLabels.email')}
                                variant={'filled'}
                                value={userCopy.email}
                                type={'email'}
                                // onChange={(e): void => setUserCopy({ ...userCopy, email: e.target.value })}
                                {...TestIDs.GET_EDITABLE_PERSONAL_DATA('EMAIL')}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <InputMask
                                disabled
                                mask={'99.99.9999'}
                                alwaysShowMask={false}
                                placeholder={''}
                                value={userCopy.birthdate}
                                // onChange={(e): void => setUserCopy({ ...userCopy, birthdate: e.target.value })}
                            >
                                {(): JSX.Element => (
                                    <TextField
                                        disabled
                                        fullWidth={true}
                                        label={t('formLabels.birthdate')}
                                        variant={'filled'}
                                        {...TestIDs.GET_EDITABLE_PERSONAL_DATA('BIRTHDATE')}
                                    />
                                )}
                            </InputMask>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth={true}
                                label={t('formLabels.telephone')}
                                variant={'filled'}
                                value={userCopy.telephone}
                                inputProps={{
                                    maxLength: 30,
                                }}
                                onChange={(e): void => {
                                    if (isPhoneRegex(e.target.value)) {
                                        setUserCopy({ ...userCopy, telephone: e.target.value })
                                    }
                                }}
                                {...TestIDs.GET_EDITABLE_PERSONAL_DATA('TELEPHONE')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth={true}
                                label={t('formLabels.mobile')}
                                variant={'filled'}
                                value={userCopy.mobile}
                                inputProps={{
                                    maxLength: 30,
                                }}
                                onChange={(e): void => {
                                    if (isPhoneRegex(e.target.value)) {
                                        setUserCopy({ ...userCopy, mobile: e.target.value })
                                    }
                                }}
                                {...TestIDs.GET_EDITABLE_PERSONAL_DATA('MOBILE_PHONE')}
                            />
                        </Grid>
                    </Grid>
                </FieldWrapper>

                <Fade in={validation.errors.length > 0} unmountOnExit={true}>
                    <Box>
                        <ErrorBox {...validation} />
                    </Box>
                </Fade>

                <Box
                    marginTop={5}
                    marginBottom={5}
                    display={'flex'}
                    flexDirection={{ xs: 'column', md: 'row' }}
                    justifyContent={'space-between'}
                >
                    <Button
                        variant={'outlined'}
                        color={'default'}
                        {...TestIDs.GET_EDITABLE_PERSONAL_DATA('CANCEL_BUTTON')}
                        onClick={handleCancel}
                    >
                        {touched ? t('general.cancel') : t('general.back')}
                    </Button>

                    <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={() => history.push(t('url.OtherAddrEdit'))}
                        {...TestIDs.GET_OTHER_ADDR('EDIT_BUTTON')}
                    >
                        {t('pages.myData.moreAddr')}
                    </Button>
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        {...TestIDs.GET_EDITABLE_PERSONAL_DATA('SUBMIT_BUTTON')}
                        onClick={handleSave}
                        disabled={loading || !touched}
                    >
                        {t('general.save')}
                        {loading ? '...' : ''}
                    </Button>
                </Box>
            </>
        </ViewWrapper>
    )
}

export default PersonalDataEdit
