import { Box, Link, Typography } from '@material-ui/core'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AppState } from 'store/store'
import colors from 'utils/colors'
import TestIDs from 'utils/TestIDs'

export interface FooterLink {
    label: string
    href?: string
}

const Footer = (): JSX.Element => {
    const { customize, user } = useSelector((state: AppState) => ({
        user: state.generalState.user,
        customize: state.generalState.customize,
    }))
    const { t } = useTranslation()
    const history = useHistory()
    const appTitle = customize?.general.title
    const currentYear = new Date().getFullYear()

    return (
        <Box
            width={1}
            bgcolor={colors.main}
            alignItems={'center'}
            marginTop={'auto'}
            padding={'13.5px'}
            display={'flex'}
            flexDirection={{ xs: 'column', sm: 'row' }}
            justifyContent={{ xs: 'center', sm: 'space-between' }}
            borderTop={`5px solid ${colors.lightBorderGray}`}
            {...TestIDs.GET_BASE('FOOTER')}
            position={'relative'}
        >
            {customize?.footer.showFooterLogo && (
                <Box
                    display={'flex'}
                    justifyContent={{ xs: 'center', sm: 'flex-start' }}
                    alignItems={'center'}
                    marginBottom={{ xs: 4, sm: 0 }}
                >
                    <img
                        src={process.env.PUBLIC_URL + '/customize/footerLogo.svg'}
                        alt={'footerLogo.svg'}
                        style={{ maxWidth: 120, width: '100%', objectFit: 'cover' }}
                    />
                </Box>
            )}
            <Box>
                <Box paddingRight={{ xs: 1, sm: 2 }}>
                    {user && customize?.footer.showCancelContract && (
                        <Typography
                            variant="subtitle2"
                            style={{
                                cursor: 'pointer',
                                color: colors.white,
                                textDecorationLine: 'underline',
                                marginLeft: 20,
                                marginRight: 20,
                            }}
                            onClick={() => history.push('/cancelContract')}
                        >
                            {t('cancelContract')}
                        </Typography>
                    )}
                </Box>
            </Box>
            <Box
                display={'flex'}
                justifyContent={{ xs: 'center', sm: 'flex-end' }}
                alignItems={'center'}
                textAlign={'center'}
                color={colors.white}
                flexWrap={'wrap'}
                height={1}
            >
                {customize &&
                    customize.footer.links.length > 0 &&
                    customize.footer.links.map((link: FooterLink, index: number): JSX.Element => {
                        return link.href ? (
                            <Box key={link.label + index} paddingRight={{ xs: 1, sm: 2 }}>
                                <Link target="_blank" href={link.href} color={'textSecondary'}>
                                    <Typography variant="subtitle2">
                                        {link.label === 'footer.company' && '© ' + currentYear + ' '}
                                        {t(link.label)}
                                    </Typography>
                                </Link>
                            </Box>
                        ) : (
                            <Box key={link.label + index} paddingRight={{ xs: 1, sm: 2 }}>
                                <Typography variant="subtitle2">
                                    {link.label === 'footer.company' && '© ' + currentYear + ' '}
                                    {t(link.label)}
                                </Typography>
                            </Box>
                        )
                    })}
            </Box>
            <Box position={'absolute'} right={'0'} bottom={'0'}>
                <Typography color="primary" variant={'subtitle2'}>
                    Version: {appTitle} Iteration: {process.env.REACT_APP_BUILD_NUMBER}
                </Typography>
            </Box>
        </Box>
    )
}

export default Footer
