import { Box, ButtonBase, Container, Grid, Link, Typography } from '@material-ui/core'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import colors from 'utils/colors'
import TestIDs from '../../utils/TestIDs'
export interface NavigationLink {
    label: string
    href: string
}
export interface NavigationBarProps {
    links: NavigationLink[]
    selectedLink: string
}
const NavigationBar = (props: NavigationBarProps): JSX.Element => {
    const { t } = useTranslation()
    const { links, selectedLink } = props
    return (
        <Box width={1} bgcolor={colors.lightGray} {...TestIDs.GET_BASE('NAVIGATION_BAR')}>
            <Container maxWidth={'md'}>
                <Grid container wrap={'wrap'}>
                    {links.map((navLink, index) => (
                        <Grid
                            key={index + (navLink.label === selectedLink ? 'true' : 'false')}
                            item
                            xs={12}
                            sm={'auto'}
                        >
                            <Link
                                component={RouterLink}
                                to={navLink.href}
                                style={{
                                    width: '100%',
                                }}
                                {...TestIDs.GET_BASE('NAVIGATION_BAR', navLink.label)}
                            >
                                <ButtonBase
                                    className={'NavButtonBase'}
                                    style={{
                                        backgroundColor: navLink.label === selectedLink ? colors.main : undefined,
                                    }}
                                >
                                    <Typography
                                        color={navLink.label !== selectedLink ? 'textPrimary' : 'textSecondary'}
                                    >
                                        {t('navigation.' + navLink.label)}
                                    </Typography>
                                </ButtonBase>
                            </Link>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    )
}
export default NavigationBar
