import { Box, Button, Fade, Grid, TextField, Typography } from '@material-ui/core'
import FieldWrapper from 'components/FieldWrapper'
import ViewWrapperPublic from 'components/ViewWrapperPublic/ViewWrapperPublic'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { getHeader } from 'utils/utils'
import { useReactivateRegistration } from './useReactivateRegistration'

const ReactivateRegistration = (): JSX.Element => {
    const { success, loading, history, customerID, setCustomerID, requestNewLink } = useReactivateRegistration()
    const { t } = useTranslation()

    return (
        <ViewWrapperPublic header={getHeader(`/${t('url.dashboard')}`, history, t, [])} loading={false}>
            <Box marginTop={'5%'} marginBottom={'15%'}>
                <Typography variant="h4" style={{ marginBottom: '10%' }}>
                    {t('pages.reactivateRegistration.title')}
                </Typography>
                <FieldWrapper text="">
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="body1">{t('pages.reactivateRegistration.helperText')}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="filled"
                                fullWidth
                                label={t('pages.reactivateRegistration.customerID')}
                                value={customerID}
                                onChange={(e) => setCustomerID(e.target.value)}
                                inputProps={{
                                    style: { backgroundColor: 'white' },
                                }}
                                style={{ marginTop: 15 }}
                            />
                        </Grid>
                        <Box style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '5%' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={loading || success}
                                onClick={requestNewLink}
                            >
                                {t('pages.reactivateRegistration.submit')}
                                {loading && <>...</>}
                            </Button>
                        </Box>
                        <Fade in={success} timeout={1000}>
                            <Grid item xs={12}>
                                <Typography variant="body1" style={{ marginTop: 15 }}>
                                    {t('pages.reactivateRegistration.message')}
                                </Typography>
                            </Grid>
                        </Fade>
                    </Grid>
                </FieldWrapper>
            </Box>
        </ViewWrapperPublic>
    )
}

export default ReactivateRegistration
