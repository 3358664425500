import { Box, ButtonBase, Typography } from '@material-ui/core'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import colors from 'utils/colors'
import TestIDs from 'utils/TestIDs'

export interface UserBarProps {
    user: string
    onLogoutClick: () => void
}

const UserBar = (props: UserBarProps): JSX.Element => {
    const { onLogoutClick, user } = props
    const { t } = useTranslation()

    return (
        <Box
            width={1}
            bgcolor={colors.main}
            display={'flex'}
            justifyContent={'flex-end'}
            color={colors.white}
            paddingLeft={1.5}
            {...TestIDs.GET_BASE('USER_BAR')}
        >
            <Box display={'flex'} paddingTop={1} paddingBottom={1} borderRight={`2px solid ${colors.white}`}>
                <Box marginRight={{ xs: 0.75, sm: 1.5 }} lineHeight={'normal'} display={'flex'} alignItems={'center'}>
                    <img alt={'person'} src={process.env.PUBLIC_URL + '/customize/icons/person.svg'} />
                </Box>
                <Box
                    paddingRight={{ xs: 0.75, sm: 1.5 }}
                    lineHeight={'normal'}
                    display={'flex'}
                    alignItems={'center'}
                    textAlign={{ xs: 'center', sm: 'left' }}
                >
                    <Typography>
                        {t('userbar.customer')}
                        {`${user}`}
                    </Typography>
                </Box>
            </Box>
            <Box display={'flex'} alignItems={'center'}>
                <ButtonBase
                    component={Typography}
                    style={{ flex: 1, paddingLeft: '12px', paddingRight: '12px', height: '100%' }}
                    onClick={onLogoutClick}
                >
                    Logout
                </ButtonBase>
            </Box>
        </Box>
    )
}

export default UserBar
