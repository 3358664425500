import { Box, Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import DashboardSectionBox from 'components/DashboardSectionBox'
import ViewWrapper from 'components/ViewWrapper'
import { useSelector } from 'react-redux'
import useDashboardState from 'pages/DashboardPage/useDashboardState'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import TestIDs from 'utils/TestIDs'
import { AppState } from 'store/store'
import { getHeader, navigationLinks } from 'utils/utils'

const DashboardPage = (): JSX.Element => {
    const { customize } = useSelector((state: AppState) => ({
        customize: state.generalState.customize,
    }))
    const { t } = useTranslation()
    const history = useHistory()

    //const { orderStatus, invoices, searchTerms } = useDashboardState()
    const { searchTerms } = useDashboardState()

    return (
        <ViewWrapper
            header={getHeader(`/${t('url.dashboard')}`, history, t, searchTerms)}
            navigation={navigationLinks('logged-in', 'dashboard', t)}
        >
            <Box display={'flex'} flexDirection={'column'} marginBottom={10}>
                <Box width={1}>
                    <Typography variant={'h1'} {...TestIDs.GET_DASHBOARD_PAGE('HEADLINE', 'MAIN')}>
                        {t('pages.dashboard.title.overview')}
                    </Typography>
                </Box>
                <Box marginTop={1}>
                    <Typography variant={'body1'} {...TestIDs.GET_DASHBOARD_PAGE('SUBLINE', 'MAIN')}>
                        {t('pages.dashboard.subTitle.overview')}
                    </Typography>
                </Box>
                <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="flex-start"
                    alignItems="stretch"
                    style={{ marginTop: 10, marginBottom: 10 }}
                >
                    {customize?.dashboard.showMyData && (
                        <Grid item xs={12} md={6}>
                            <DashboardSectionBox
                                title={t('pages.dashboard.myData.title')}
                                isTitleClickable={true}
                                buttonProps={{
                                    label: t('pages.dashboard.myData.submitButton'),
                                    onClick: (): void => history.push(`/${t('url.myData')}`),
                                }}
                                testCategory={'MY_DATA_DASHBOARD'}
                                height={'100%'}
                            >
                                <Typography style={{ marginTop: 16 }}>{t('pages.dashboard.myData.helper')}</Typography>
                            </DashboardSectionBox>
                        </Grid>
                    )}
                    {customize?.dashboard.showLastInvoice && (
                        <Grid item xs={12} md={6}>
                            <DashboardSectionBox
                                title={t('pages.dashboard.lastInvoice.title')}
                                isTitleClickable={true}
                                buttonProps={{
                                    label: t('pages.dashboard.lastInvoice.submitButton'),
                                    onClick: (): void => history.push(`/${t('url.myInvoices')}`),
                                }}
                                testCategory={'LAST_INVOICE'}
                                height={'100%'}
                            >
                                <Typography style={{ marginTop: 16 }}>
                                    {t('pages.dashboard.lastInvoice.helper')}
                                </Typography>
                            </DashboardSectionBox>
                        </Grid>
                    )}
                    {customize?.dashboard.showMyContracts && (
                        <Grid item xs={12} md={6}>
                            <DashboardSectionBox
                                title={t('pages.dashboard.myContracts.title')}
                                isTitleClickable={true}
                                buttonProps={{
                                    label: t('pages.dashboard.myContracts.submitButton'),
                                    onClick: (): void => history.push('/contractOverview'),
                                }}
                                buttonProps2={{
                                    label: t('pages.dashboard.myContracts.cancelButton'),
                                    onClick: (): void => history.push('/cancelContract'),
                                }}
                                testCategory={'CONTRACT_OVERVIEW'}
                            >
                                <Typography style={{ marginTop: 16 }}>
                                    {t('pages.dashboard.myContracts.helper')}
                                </Typography>
                            </DashboardSectionBox>
                        </Grid>
                    )}
                    {customize?.dashboard.showMyAdressData && (
                        <Grid item xs={12} md={6}>
                            <DashboardSectionBox
                                title={t('pages.dashboard.myAdressData.title')}
                                isTitleClickable={true}
                                buttonProps={{
                                    label: t('pages.dashboard.myAdressData.submitButton'),
                                    onClick: (): void => history.push(`/${t('url.OtherAddrEdit')}`),
                                }}
                                testCategory={'MY_ADRESSDATA_DASHBOARD'}
                                height={'100%'}
                            >
                                <Typography style={{ marginTop: 16 }}>
                                    {t('pages.dashboard.myAdressData.helper')}
                                </Typography>
                            </DashboardSectionBox>
                        </Grid>
                    )}
                </Grid>
            </Box>
        </ViewWrapper>
    )
}

export default DashboardPage
