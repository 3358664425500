import { Box, Grid, Typography } from '@material-ui/core'
import LoginData from 'components/LoginData'
import MyBankData from 'components/MyBankData'
import OtherAddr from 'components/OtherAddr'
import PersonalData from 'components/PersonalData'
import ViewWrapper from 'components/ViewWrapper'
import useMyDataState from 'pages/MyDataPage/useMyDataState'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import TestIDs from 'utils/TestIDs'
import { getHeader, navigationLinks } from 'utils/utils'

const MyDataPage = (): JSX.Element => {
    const { t } = useTranslation()
    const history = useHistory()
    const { user, searchTerms, handlePasswordChange } = useMyDataState()

    if (Object.keys(user).length === 0) {
        history.push('/')
        return <></>
    }

    return (
        <ViewWrapper
            header={getHeader(`/${t('url.dashboard')}`, history, t, searchTerms)}
            navigation={navigationLinks('logged-in', 'myData', t)}
        >
            <Box display={'flex'} flexDirection={'column'}>
                <Box width={1} marginBottom={9}>
                    <Typography variant={'h1'} {...TestIDs.GET_MY_DATA_PAGE('HEADLINE')}>
                        {t('pages.myData.title')}
                    </Typography>
                    <Box marginTop={1}>
                        <Typography variant="body1">{t('pages.myData.subTitle')}</Typography>
                    </Box>
                </Box>
                <Box marginBottom={7.5} {...TestIDs.GET_MY_DATA_PAGE('MY_DATA')}>
                    <PersonalData user={user} />
                </Box>
                <Grid
                    container
                    spacing={1}
                    direction="row"
                    justify="space-between"
                    alignItems="stretch"
                    style={{ marginBottom: '7.5%' }}
                >
                    <Grid item xs>
                        <OtherAddr />
                    </Grid>
                    <Grid item xs>
                        <MyBankData data={user.bankData} />
                    </Grid>
                </Grid>
                <Box marginBottom={7.5} {...TestIDs.GET_MY_DATA_PAGE('LOGIN')}>
                    <LoginData
                        data={{
                            username: user.customerID,
                            password: '',
                        }}
                        handlePasswordchange={handlePasswordChange}
                    />
                </Box>
                {/*
                <Box marginBottom={7.5} {...TestIDs.GET_MY_DATA_PAGE('NEWSLETTER')}>
                    <NewsletterData
                        data={{
                            email: user.newsletterEmail,
                            offers: user.offers,
                            tips: user.tips,
                        }}
                    />
                </Box>

                    */}
            </Box>
        </ViewWrapper>
    )
}

export default MyDataPage
