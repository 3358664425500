import { useMutation } from '@apollo/client'
import { Box, Button, Fade, Grid, TextField, Typography, useTheme } from '@material-ui/core'
import Dropdown from 'components/Dropdown'
import ErrorBox from 'components/ErrorBox'
import FieldWrapper from 'components/FieldWrapper'
import IbanInput from 'components/IbanInput'
import ViewWrapper from 'components/ViewWrapper'
import { UpdateCustomerAddress } from 'graphql/mutations/UpdateCustomerAddress'
import { Mutation, MutationUpdateCustomerAddressArgs } from 'graphql/types'
import useEditState from 'hooks/useEditState'
import { checkAddress, editField } from 'hooks/useEditState/EditStateFuncs'
import _, { uniq } from 'lodash'
import ChangeInvoice from 'pages/ChangeInvoice/ChangeInvoice'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TestIDs from 'utils/TestIDs'
import { dropdownOptionsCountry, dropdownOptionsSalutation, getHeader, navigationLinks } from 'utils/utils'

const BankDataEdit = (): JSX.Element => {
    const {
        userCopy,
        history,
        searchTerms,
        user,
        saveUserData,
        validation,
        setValidation,
        touched,
        setTouched,
        savePressed,
        setSavePressed,
        customize,
    } = useEditState(editField.bankData)

    if (!user || Object.keys(user ?? {}).length === 0) {
        history.replace('/')
        return <></>
    }
    const addressKind = 'BILLING'
    //const [addressKind, setAddressKind] = useState<string>('DEFAULT')
    const { t } = useTranslation()
    const theme = useTheme()
    // show diffrent accountholder when there one
    // else use billing / location addresse
    //let addressToUse = userCopy.billingAddress

    //if (addressIsFilled(userCopy.accountHolderAddress)) {
    //addressToUse = userCopy.accountHolderAddress
    //} else if (addressIsFilled(userCopy.billingAddress)) {
    //addressToUse = userCopy.billingAddress
    //}

    const [displayBillingAddr, setDisplayBillingAddr] = useState(userCopy.billingAddress)

    const [postAddress, { loading }] = useMutation<Mutation>(UpdateCustomerAddress, {
        onCompleted: (data) => {
            if (data.updateCustomerAddress.Success === true) {
                if (addressKind === 'BILLING' && user) {
                    saveUserData({ ...user, billingAddress: displayBillingAddr })
                }
                //if (addressKind === 'ACCOUNTHOLDER' && user) {
                //saveUserData({ ...user, billingAddress: displayBillingAddr })
                //}
            }
        },
    })

    const handleSave = async (): Promise<void> => {
        //if (addressKind === '' || addressKind === 'DEFAULT') {
        //return
        //}
        if (checkAddress(displayBillingAddr).length === 0) {
            //if (addressKind === 'BILLING' || addressKind === 'ACCOUNTHOLDER') {
            if (addressKind === 'BILLING') {
                const vars: MutationUpdateCustomerAddressArgs = {
                    input: {
                        salutation: displayBillingAddr.salutation,
                        title: displayBillingAddr.title,
                        firstName: displayBillingAddr.firstName,
                        lastName: displayBillingAddr.lastName,
                        street: displayBillingAddr.street,
                        houseNumber: displayBillingAddr.houseNumber,
                        houseNumberExt: displayBillingAddr.houseNumberExt,
                        zipCode: displayBillingAddr.zipCode,
                        city: displayBillingAddr.city,
                        country: displayBillingAddr.country,
                    },
                    kind: addressKind,
                }
                await postAddress({ variables: vars })
            }
        } else {
            if (!savePressed) {
                setSavePressed(true)
            }
        }
    }

    const handleCancel = (): void => {
        history.goBack()
    }

    useEffect(() => {
        if (touched === false) {
            if (!_.isEqual(displayBillingAddr, userCopy.billingAddress)) {
                setTouched(true)
            }
        }

        if (savePressed) {
            const errors = uniq(checkAddress(displayBillingAddr))

            setValidation({
                errorsTitle: 'registrationPersonalData',
                errors: errors,
            })
        }
    }, [displayBillingAddr, savePressed])

    return (
        <ViewWrapper
            header={getHeader(`/${t('url.dashboard')}`, history, t, searchTerms)}
            navigation={navigationLinks('logged-in', 'myData', t)}
        >
            <>
                <Box marginBottom={10}>
                    <Typography variant={'h1'}>{t('pages.myData.edit.myBankData')}</Typography>
                </Box>
                <Box marginBottom={5}>
                    <Typography variant={'h2'}>{t('pages.myData.edit.BankData')}</Typography>
                </Box>

                <FieldWrapper text={''}>
                    <Grid container item spacing={2} style={{ backgroundColor: theme.palette.primary.light }}>
                        <>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth={true}
                                    label={t('formLabels.accountHolder')}
                                    variant={'filled'}
                                    disabled
                                    value={userCopy.bankData.accountHolder}
                                    {...TestIDs.GET_BANK_DATA_EDIT('ACCOUNT_HOLDER')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <IbanInput
                                    disabled={true}
                                    value={userCopy.bankData.iban}
                                    {...TestIDs.GET_BANK_DATA_EDIT('IBAN')}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth={true}
                                    label={t('formLabels.BIC')}
                                    variant={'filled'}
                                    value={userCopy.bankData.bic}
                                    disabled
                                    {...TestIDs.GET_BANK_DATA_EDIT('BIC')}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth={true}
                                    label={t('formLabels.creditorID')}
                                    variant={'filled'}
                                    value={userCopy.bankData.creditorID}
                                    disabled
                                    {...TestIDs.GET_BANK_DATA_EDIT('BIC')}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth={true}
                                    label={t('formLabels.mandateRef')}
                                    variant={'filled'}
                                    disabled
                                    value={userCopy.bankData.Mandatsreferenz}
                                    {...TestIDs.GET_BANK_DATA_EDIT('MANDATE_REF')}
                                />
                            </Grid>
                        </>
                    </Grid>
                </FieldWrapper>

                <Box style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 20 }}>
                    <Button
                        variant={'outlined'}
                        onClick={() => history.push('/newSEPA')}
                        {...TestIDs.GET_BANK_DATA_EDIT('NEW_SEPA_BUTTON')}
                    >
                        {t('pages.myData.edit.newSEPA')}
                    </Button>
                </Box>
                <>
                    {customize && customize.invoice.showChangeSending && <ChangeInvoice />}
                    <Box marginBottom={5} marginTop={5}>
                        <Typography variant={'h2'}>{t('pages.myData.edit.BillingAddr')}</Typography>
                    </Box>

                    <FieldWrapper text="">
                        <Grid container item spacing={2} style={{ backgroundColor: theme.palette.primary.light }}>
                            <Grid item xs={12} md={6}>
                                <Dropdown
                                    id={'salutation'}
                                    disabled={false}
                                    focused={undefined}
                                    label={t('formLabels.salutation')}
                                    options={dropdownOptionsSalutation(t)}
                                    selectedOption={displayBillingAddr.salutation}
                                    handleChange={(e) => {
                                        setDisplayBillingAddr({
                                            ...displayBillingAddr,
                                            salutation: e.target.value as string,
                                        })
                                    }}
                                    testIdentifier={TestIDs.GET_BANK_DATA_EDIT('SALUTATION')}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth={true}
                                    label={t('formLabels.title')}
                                    variant={'filled'}
                                    value={displayBillingAddr.title}
                                    inputProps={{
                                        maxLength: 30,
                                    }}
                                    {...TestIDs.GET_BANK_DATA_EDIT('TITLE')}
                                    onChange={(e) => {
                                        setDisplayBillingAddr({
                                            ...displayBillingAddr,
                                            title: e.currentTarget.value,
                                        })
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth={true}
                                    label={t('formLabels.firstName')}
                                    variant={'filled'}
                                    inputProps={{
                                        maxLength: 50,
                                    }}
                                    value={displayBillingAddr.firstName}
                                    {...TestIDs.GET_BANK_DATA_EDIT('FNAME')}
                                    onChange={(e) => {
                                        setDisplayBillingAddr({
                                            ...displayBillingAddr,
                                            firstName: e.currentTarget.value,
                                        })
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth={true}
                                    label={t('formLabels.lastName')}
                                    variant={'filled'}
                                    inputProps={{
                                        maxLength: 50,
                                    }}
                                    value={displayBillingAddr.lastName}
                                    {...TestIDs.GET_BANK_DATA_EDIT('LNAME')}
                                    onChange={(e) => {
                                        setDisplayBillingAddr({
                                            ...displayBillingAddr,
                                            lastName: e.currentTarget.value,
                                        })
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={7}>
                                <TextField
                                    fullWidth={true}
                                    label={t('formLabels.street')}
                                    variant={'filled'}
                                    inputProps={{
                                        maxLength: 50,
                                    }}
                                    value={displayBillingAddr.street}
                                    {...TestIDs.GET_BANK_DATA_EDIT('SRTEET')}
                                    onChange={(e) => {
                                        setDisplayBillingAddr({ ...displayBillingAddr, street: e.currentTarget.value })
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth={true}
                                    label={t('formLabels.houseNumber')}
                                    variant={'filled'}
                                    inputProps={{
                                        maxLength: 10,
                                    }}
                                    value={displayBillingAddr.houseNumber}
                                    {...TestIDs.GET_BANK_DATA_EDIT('HOUSE_NUMBER')}
                                    onChange={(e) => {
                                        setDisplayBillingAddr({
                                            ...displayBillingAddr,
                                            houseNumber: e.currentTarget.value,
                                        })
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={2}>
                                <TextField
                                    fullWidth={true}
                                    label={t('formLabels.houseNumberExt')}
                                    variant={'filled'}
                                    inputProps={{
                                        maxLength: 10,
                                    }}
                                    value={displayBillingAddr.houseNumberExt}
                                    {...TestIDs.GET_BANK_DATA_EDIT('HOUSE_NUMBER_EXT')}
                                    onChange={(e) => {
                                        setDisplayBillingAddr({
                                            ...displayBillingAddr,
                                            houseNumberExt: e.currentTarget.value,
                                        })
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth={true}
                                    label={t('formLabels.zip')}
                                    variant={'filled'}
                                    inputProps={{
                                        maxLength: 5,
                                    }}
                                    value={displayBillingAddr.zipCode}
                                    onChange={(e) => {
                                        setDisplayBillingAddr({ ...displayBillingAddr, zipCode: e.currentTarget.value })
                                    }}
                                    {...TestIDs.GET_BANK_DATA_EDIT('ZIP')}
                                />
                            </Grid>

                            <Grid item xs={12} md={9}>
                                <TextField
                                    fullWidth={true}
                                    label={t('formLabels.city')}
                                    variant={'filled'}
                                    inputProps={{
                                        maxLength: 50,
                                    }}
                                    value={displayBillingAddr.city}
                                    onChange={(e) => {
                                        setDisplayBillingAddr({ ...displayBillingAddr, city: e.currentTarget.value })
                                    }}
                                    {...TestIDs.GET_BANK_DATA_EDIT('CITY')}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Dropdown
                                    id={'country'}
                                    disabled={false}
                                    focused={undefined}
                                    label={t('formLabels.country')}
                                    options={dropdownOptionsCountry(t)}
                                    selectedOption={displayBillingAddr.country}
                                    handleChange={(e) => {
                                        setDisplayBillingAddr({
                                            ...displayBillingAddr,
                                            country: e.target.value as string,
                                        })
                                    }}
                                    testIdentifier={TestIDs.GET_OTHER_ADDR_EDIT('D-COUNTRY')}
                                />
                            </Grid>
                        </Grid>
                    </FieldWrapper>
                </>

                <Fade in={validation.errors.length > 0} unmountOnExit={true}>
                    <Box>
                        <ErrorBox {...validation} />
                    </Box>
                </Fade>

                <Box
                    marginTop={5}
                    marginBottom={5}
                    display={'flex'}
                    flexDirection={{ xs: 'column', md: 'row' }}
                    justifyContent={'space-between'}
                >
                    <Button
                        variant={'outlined'}
                        color={'default'}
                        {...TestIDs.GET_BANK_DATA_EDIT('CANCEL_BUTTON')}
                        onClick={handleCancel}
                    >
                        {touched ? t('general.cancel') : t('general.back')}
                    </Button>

                    <Button
                        variant={'contained'}
                        color={'primary'}
                        {...TestIDs.GET_BANK_DATA_EDIT('SUBMIT_BUTTON')}
                        onClick={handleSave}
                        disabled={loading || !touched || validation.errors.length > 0}
                    >
                        {t('general.save')}
                        {loading ? '...' : ''}
                    </Button>
                </Box>
            </>
        </ViewWrapper>
    )
}

export default BankDataEdit
