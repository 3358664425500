const colors = {
    appBarBackground: '#ffffff',
    lightShadow: '#F0F0F0',
    black: '#000000',
    button: '#A5D82A',
    darkGray: '#222222',
    focusedField: '#1d3454',
    main: '#1d3454',
    lightMain: '#C89EEC',
    lighterMain: 'rgba(29, 52, 84, 0.5)',
    lightBorderGray: '#E1E1E1',
    lightGray: '#F3F0F0',
    lightDarkerGray: '#BFBFBF',
    darkIcon: '#263238',
    labelColor: '#929292',
    lightWarning: 'rgba(255, 0, 0, 0.20)',
    midGray: '#C4C4C4',
    midDarkerGray: '#737373',
    warning: '#FF0000',
    white: '#FFFFFF',
}

export default colors
