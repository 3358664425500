import { AppBar, Box, Button, Grid, makeStyles, Tab, Tabs, Typography } from '@material-ui/core'
import FieldWrapper from 'components/FieldWrapper'
import ProductTab from 'components/ProductTab'
import TabPanel from 'components/TabPanel'
import { ContractOverview } from 'graphql/types'
import React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import colors from 'utils/colors'

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        padding: 0,
    },
    customBar: {
        color: 'black',
        padding: 0,
        borderBottom: 0,
        backgroundColor: '#FAFAFA',
    },
    customIndicator: {
        backgroundColor: colors.lightGray,
        height: 0,
    },
})

export interface ContractDetailsProps {
    customerID: string
    gotoDocs: () => void
    overview: ContractOverview
    activeContractIDs: string[]
    handleChangeContractOverview: (index: number) => void
}

const ContractDetails = (props: ContractDetailsProps): JSX.Element => {
    const classes = useStyles()
    const { customerID, gotoDocs, overview, activeContractIDs, handleChangeContractOverview } = props
    const { t } = useTranslation()
    let preSelectedItem = 0
    if (overview.inet) {
        preSelectedItem = 0
    } else if (overview.tv) {
        preSelectedItem = 1
    } else if (overview.phone) {
        preSelectedItem = 1
    }
    const [value, setValue] = useState(preSelectedItem)
    const [selectedContractIndex, setSelectedContractIndex] = useState(0)

    const handleChange = (_: any, newValue: number) => {
        setValue(newValue)
    }

    const handleNextContract = (index: number) => {
        setSelectedContractIndex(index)
        handleChangeContractOverview(index)
    }

    return (
        <Grid container justify="center" alignItems="center" style={{ marginBottom: 100 }}>
            <Grid item xs={12}>
                <Box width={1} marginBottom={9}>
                    <Typography variant="h1">{t('pages.myContracts.title')}</Typography>
                    <Box marginTop={1}>
                        <Typography variant="body1">{t('pages.myContracts.subTitle')}</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h2" style={{ marginBottom: 40 }}>
                    {t('pages.myContracts.cust')}
                </Typography>
                <FieldWrapper text="">
                    <>
                        <Typography variant="body1" display="inline" style={{ color: colors.midDarkerGray }}>
                            {t('pages.myContracts.cust')}
                            <span style={{ paddingLeft: 15, color: colors.black }}>{customerID}</span>
                        </Typography>
                    </>
                </FieldWrapper>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h2" style={{ marginBottom: 40, marginTop: 40 }}>
                    {t('pages.myContracts.contract')}
                </Typography>
                <FieldWrapper text="">
                    <>
                        <Typography variant="body1" display="inline" style={{ color: colors.black }}>
                            {t('pages.myContracts.contractText')}
                        </Typography>
                    </>
                </FieldWrapper>
                <Box
                    display="flex"
                    justifyContent={{ xs: 'center', sm: 'flex-end' }}
                    alignItems="center"
                    style={{ paddingTop: 30, paddingBottom: 30 }}
                >
                    <Button variant={'outlined'} onClick={gotoDocs}>
                        {t('pages.myContracts.toDocs')}
                    </Button>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <div className={classes.root}>
                    {activeContractIDs.length > 0 && (
                        <Grid container justify="flex-start" alignItems="flex-start" style={{ marginBottom: 10 }}>
                            {activeContractIDs.map((contractID, index) => {
                                const isSelected = selectedContractIndex === index
                                return (
                                    <Grid item xs={12} sm="auto" key={index + contractID}>
                                        <Button
                                            fullWidth
                                            variant={isSelected ? 'contained' : 'text'}
                                            color={isSelected ? 'primary' : undefined}
                                            onClick={() => handleNextContract(index)}
                                        >
                                            {contractID}
                                        </Button>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    )}
                    <AppBar position="static" className={classes.customBar}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            TabIndicatorProps={{ className: classes.customIndicator }}
                        >
                            <Tab
                                label={t('pages.contractDetails.inet')}
                                disabled={overview.inet === null}
                                style={{
                                    backgroundColor:
                                        value === 0 && overview.inet !== null ? colors.main : colors.lightGray,
                                    color: value === 0 && overview.inet !== null ? colors.white : colors.black,
                                    opacity: overview.inet === null ? 0.5 : 1,
                                    minHeight: 80,
                                }}
                            />
                            <Tab
                                label={t('pages.contractDetails.tv')}
                                disabled={overview.tv === null}
                                style={{
                                    backgroundColor:
                                        value === 1 && overview.tv !== null ? colors.main : colors.lightGray,
                                    color: value === 1 && overview.tv !== null ? colors.white : colors.black,
                                    opacity: overview.tv === null ? 0.5 : 1,
                                    minHeight: 80,
                                }}
                            />
                            <Tab
                                label={t('pages.contractDetails.phone')}
                                disabled={overview.phone === null}
                                style={{
                                    backgroundColor:
                                        value === 2 && overview.phone !== null ? colors.main : colors.lightGray,
                                    color: value === 2 && overview.phone !== null ? colors.white : colors.black,
                                    opacity: overview.phone === null ? 0.5 : 1,
                                    minHeight: 80,
                                }}
                            />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0}>
                        {overview.inet && (
                            <ProductTab
                                datasheetLink={overview.datasheetLink}
                                productInfo={overview.inet}
                                contractID={activeContractIDs[selectedContractIndex]}
                            />
                        )}
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        {overview.tv && (
                            <ProductTab
                                datasheetLink={''}
                                productInfo={overview.tv}
                                contractID={activeContractIDs[selectedContractIndex]}
                            />
                        )}
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        {overview.phone && (
                            <ProductTab
                                datasheetLink={overview.datasheetLink}
                                productInfo={overview.phone}
                                contractID={activeContractIDs[selectedContractIndex]}
                            />
                        )}
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <></>
                    </TabPanel>
                </div>
            </Grid>
        </Grid>
    )
}

export default ContractDetails
